import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import Dygraph from 'dygraphs';
import { Loading } from 'core/components';
import T from 'modules/i18n';
import './css/thermiair.css';
import { requestData } from 'core/ducks/list';

class Pollutionvariation extends Component {

	constructor(props) {
		super(props);

		this.state = {
			pollutants: [],
			refreshing: true,
			data: null,
			activeStation: null,
			activeStationLabel: null,
		};

		this.chooseStationHandle = this.chooseStationHandle.bind(this);
		this.createChart = this.createChart.bind(this);
		this.createAvg = this.createAvg.bind(this);
	}

	componentDidMount() {

		if (this.props.location.data) {
			this.setState({
				activeStation: this.props.location.data,
				activeStationLabel: this.props.location.data.label
			});
		}

		this.setStationsData();
	}

	setStationsData() {
		this.setState({ refreshing: true });
		this.props.dispatch(requestData('dashboardData', 'data/bbox/180,180,-180,-180,4326')).then(() => {
			this.setState({ refreshing: false, data: this.props.dashboardDataList }, () => {
				this.setData();
			});

		});
	}

	setColor(value, timestamp, type) {

		let measure_date = moment(timestamp);
		let now_date = moment();
		let diff = now_date.diff(measure_date, 'h');

		let s = { padding: '10px', margin: '20px', border: '1px solid lightgray' };
		if (diff > 2) {
			s = { ...s, background: '#a0a0a0', opacity: '0.7' };
		} else if (type === 'AQI') {
			if (value >= 0 && value < 10) {
				s = { ...s, background: '#5ed5ff' };
			} else if (value >= 10 && value < 20) {
				s = { ...s, background: '#92d14f' };
			} else if (value >= 20 && value < 25) {
				s = { ...s, background: '#ffff00' };
			} else if (value >= 25 && value < 50) {
				s = { ...s, background: '#fc3903', color: 'white' };
			} else if (value >= 50) {
				s = { ...s, background: '#990100', color: 'white' };
			}
		}
		return s;
	}

	chooseStationHandle(e) {
		let activeStation = this.state.data.find(item => {
			return item.label === e.target.value;
		})
		this.setState({
			activeStation,
			activeStationLabel: activeStation.label,
			pollutants: [],
		}, this.setData);
	}

	setData() {

		const types = [];

		if (this.state.activeStation) {

			this.state.activeStation.datastreams.forEach(j => {
				this.addObjectIfNotExists(types, {
					token: j.token,
					type: j.property.symbol
				})
			});

			const initialValues = {
				charts: ['month'],
				avg10min: null,
				avg30min: null,
				avg1hr: null,
				avg6hr: null,
				avgDay: null,
				avgWeek: null,
				timestamp: null
			};

			const refreshing = types.map(type => ({
				[`refreshingAvg${type.type}`]: true,
				[`refreshingData${type.type}`]: true,
			}));

			const mergedRefreshing = refreshing.reduce((result, obj) => {
				Object.keys(obj).forEach(key => {
						result[key] = obj[key];
				});
				return result;
		}, {});

			this.setState({
				...mergedRefreshing,
				pollutants: types.map(type => ({
					...type,
					...initialValues
				}))
			}, () => {
				types.forEach((p, i) => {
					this.props.dispatch(requestData(`${p.type}AvgData`, 'data/token/' + p.token)).then((response) => {
						this.setState({ [`refreshingAvg${p.type}`]: false }, () => { this.createAvg(i, p.type, response) });
					});
					this.props.dispatch(requestData(`${p.type}MonthData`, 'data/token/' + p.token + '/timeseries/1+month')).then((response) => {
						this.setState({ [`refreshingData${p.type}`]: false }, () => { this.createChart(i, response.series) });
					});
				});
			});
		}
	}

	createAvg(idx, type, data) {
		const array = [...this.state.pollutants];
		let digits = type === 'AQI' ? 0 : 2;

		array[idx] = {
			type,
			charts: ['month'],
			avg10min: data['10 minutes'] ? data['10 minutes'].toFixed(digits) : '',
			avg30min: data['30 minutes'] ? data['30 minutes'].toFixed(digits) : '',
			avg1hr: data['1 hour'] ? data['1 hour'].toFixed(digits) : '',
			avg6hr: data['6 hours'] ? data['6 hours'].toFixed(digits) : '',
			avgDay: data['24 hours'] ? data['24 hours'].toFixed(digits) : '',
			avgWeek: data['1 week'] ? data['1 week'].toFixed(digits) : '',
			timestamp: data['time'],
			token: data['token'],
			unit: data['unit']
		};
		this.setState({ pollutants: array });
	}

	createChart(idx, data) {
		if (!data)
			return;
		const { messages } = this.props.i18n || { messages: {} };
		let digits = idx === 0 ? 0 : 2;

		let series = data.map(item => [new Date(item.result_time), Number(parseFloat(item.value).toFixed(digits))]);
		series.sort((a, b) => {
			return a[0] - b[0];
		});
		new Dygraph(
			this.state.pollutants[idx].type + "-" + this.state.pollutants[idx].charts[0],
			series,
			{
				labels: ['Date', messages['thermiairValue']],
				title: messages['thermiairVariation'],
				fillGraph: true,
				showRangeSelector: true
			}
		);
	}

	addObjectIfNotExists(arr, obj) {
		let exists = arr.some(item => {
			return JSON.stringify(item) === JSON.stringify(obj);
		});

		if (!exists) {
			arr.push(obj);
		}
	}

	render() {

		let avgTypes = {
			// avg10min: <span>10 <T>thermiairMin</T></span>,
			// avg30min: <span>30 <T>thermiairMin</T></span>,
			// avg1hr: <span>1 <T>thermiairHour</T></span>,
			avg6hr: <span>6 <T>thermiairHours</T></span>,
			avgDay: <T>thermiairDay</T>,
			avgWeek: <T>thermiairWeek</T>
		};
		const { messages } = this.props.i18n || { messages: {} };

		return (
			<div className="">
				<Container>
					<Row className="justify-content-center">
						<Col md={12}>
							{
								this.props.waitDashboardData ?
									<Loading /> :
									<Card>
										<CardBody className="text-center">
											<Row className="justify-content-center">
												<Col md={6}>
													<FormGroup row>
														<Label for="stationSelect" md={4}
															size="lg"><T>thermiairSensor</T></Label>
														<Col md={8}>
															<Input type="select" name="select" id="stationSelect"
																value={this.state.activeStationLabel}
																onChange={this.chooseStationHandle}>
																<option key={'option-0'} />
																{!this.state.refreshing &&
																	this.state.data.map(item => (
																		<option key={item.label} value={item.label}>
																			{item.description}
																		</option>
																	))}
															</Input>
														</Col>
													</FormGroup>
												</Col>
											</Row>
											{this.state.pollutants.map((item) =>
												<div>
													<hr />
													<Row className="justify-content-center" style={{
														height: '50px',
														background: 'aliceblue',
														margin: '0 0.1em'
													}}>
														<Col md={12}>
															<h5>{item.type}</h5>
														</Col>
													</Row>
													<Row className="justify-content-center">

														<Col md={4}>
															<Row
																className="justify-content-center">{messages['thermiairAvg']}</Row>
															<Row className="justify-content-center">
																{
																	this.state[`refreshingAvg${item.type}`] === false ?
																		[0, 1].map(col =>
																			<Col lg="8" xl="8">
																				{Object.keys(avgTypes).filter((avg, index) => Math.floor(index / 3) === col).map((avg, avgIndex) =>
																					<div className="pollution-variation-div" style={this.setColor(item[avg], item.timestamp, item.type)}>
																						<div>{avgTypes[avg]}</div>
																						{item[avg] === '' ? '-' : item[avg]} {item.type === 'AQI' ? ' ' : item[avg] === '' ? '' : item.unit ? item.unit.symbol : ''}
																					</div>
																				)}
																			</Col>
																		) : <div style={{ height: '100px', width: '100%' }}><Loading /></div>
																}
															</Row>
														</Col>
														<Col md={8}>
															{
																this.state[`refreshingData${item.type}`] === false ?
																	<div id={item.type + "-" + item.charts[0]} className="dygraph-div" />
																	: <div style={{ height: '100px', width: '100%' }}><Loading /></div>
															}
														</Col>
													</Row>
												</div>
											)}
										</CardBody>
									</Card>
							}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile,
	i18n: state.i18n,
	notifications: state.notifications.messages,
	dashboardDataList: state.list.dashboardData.data,
	waitDashboardData: state.list.dashboardData.pending
});

Pollutionvariation = connect(mapStateToProps)(Pollutionvariation);

export default Pollutionvariation;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'reactstrap';
import { Map as LeafletMap, TileLayer, FeatureGroup, Marker } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import { getData, updateData, deleteData } from 'core/ducks/update';
import { initContext, setContext } from 'core/ducks/context';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
	iconSize: [12, 20],
	iconAnchor: [6, 20],
	popupAnchor: [1, -34],
	shadowSize: [20, 20]
});
L.EditToolbar.Delete.include({
	removeAllLayers: false
});

const geojsonMarkerOptions = {
	radius: 8,
	fillColor: "#ff7800",
	color: "#000",
	weight: 1,
	opacity: 1,
	fillOpacity: 0.8
};

const getStyle = (feature, latlng) => {
	return L.circleMarker(latlng, {...geojsonMarkerOptions, fillColor: feature.properties.active ? "#ff7800" : "#888888"});
};

const round = (number) => {
	return Math.round((number + Number.EPSILON) * 100000000) / 100000000
};

class Things extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isEditEnabled: false,
			activeLayer: null,
			activeLayerFull: null,
			leafletLocaleReady: false,
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, this.props.dispatch);
		this.mapRef = null;
		this.FGRef = null;
	}

	componentDidMount() {
		this.leafletLocale();
		this.props.dispatch(initContext({...this.state, set: this.setContext, update: this.getGeoJSON, moveMarker: this.moveMarker}));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState !== this.state) {
			const newState = {};
			Object.keys(this.state).forEach(key => {
				if (prevState[key] !== this.state[key])
					newState[key] = this.state[key];
			});
			this.props.dispatch(setContext(newState));
		}

		if (prevProps.i18n.pending && !this.props.i18n.pending)
			this.leafletLocale();
	}

	leafletLocale = () => {
		const { messages } = this.props.i18n || {messages: {}};
		this.setState({leafletLocaleReady: false}, () => {
			L.drawLocal = {
				...L.drawLocal,
				draw: {
					...L.drawLocal.draw,
					toolbar: {
						...L.drawLocal.draw.toolbar,
						actions: {
							...L.drawLocal.draw.toolbar.actions,
							title: messages['discard all changes'] || 'Discard all changes',
							text: messages.cancel || 'Cancel',
						},
						buttons: {
							...L.drawLocal.draw.toolbar.buttons,
							circlemarker: messages['new station'] || 'New station',
						},
					},
					handlers: {
						...L.drawLocal.draw.handlers,
						circlemarker: {
							tooltip: {
								start: messages['click on map to add a station'] || 'Click on map to add a station',
							},
						},
					},
				},
				edit: {
					...L.drawLocal.edit,
					toolbar: {
						...L.drawLocal.edit.toolbar,
						actions: {
							...L.drawLocal.edit.toolbar.actions,
							save: {
								title: messages['save station coordinates'] || 'Save',
								text: messages.save || 'Save'
							},
							cancel: {
								title: messages['discard all changes'] || 'Discard all changes',
								text: messages.cancel || 'Cancel',
							},
						},
						buttons: {
							...L.drawLocal.edit.toolbar.buttons,
							edit: messages['move station'] || 'Move station',
							remove: messages['remove station'] || 'Remove station'
						},
					},
					handlers: {
						...L.drawLocal.edit.handlers,
						edit: {
							tooltip: {
								text: messages['drag a station to move'] || 'Drag a station to move',
								subtext: messages['click cancel to undo'] || 'Click Cancel to undo',
							}
						},
						remove: {
							tooltip: {
								text: messages['click a station to remove'] || 'Click a station to remove'
							}
						}
					}
				}
			};
			this.setState({leafletLocaleReady: true});
		});
	}

	setContext = (newContext) => {
		this.setState({...newContext});
	}

	moveMarker = (coordinates) => {
		const { activeLayer, activeLayerFull } = this.state;
		const newLayer = {...activeLayer, geometry: {...activeLayer.geometry, coordinates: [...coordinates]}};
		let newGeoJSON = new L.GeoJSON(newLayer, {pointToLayer: getStyle});
		let leafletFG = this.FGRef.leafletElement;
		leafletFG.removeLayer(activeLayerFull);
		newGeoJSON.eachLayer(layer => {
			layer.setStyle({...activeLayerFull.options});
			leafletFG.addLayer(layer);
			this.setState({activeLayerFull: layer});
		});
		this.setState({activeLayer: newLayer});
	}

	onMapReady = (mapRef) => {
		if (!mapRef) return;
		this.mapRef = mapRef;
	}

	onFGReady = (FGRef) => {
		if (!FGRef) return;
		this.FGRef = FGRef;
		this.getGeoJSON();
	}

	getGeoJSON = () => {
		const { _northEast, _southWest } = this.mapRef.leafletElement.getBounds();
		let bounds = [_northEast.lng, _northEast.lat, _southWest.lng, _southWest.lat];
		bounds = bounds.map(coordinate => {
			return round(coordinate);
		}).join(',') + ',4326';
		let leafletFG = this.FGRef.leafletElement;
		leafletFG.clearLayers();
		this.props.dispatch(
			getData(`thing/output/geojson/bbox/${bounds}`)
		).then(geojson => {
			let leafletGeoJSON = new L.GeoJSON(geojson, {pointToLayer: getStyle});
			leafletGeoJSON.eachLayer(layer => {
				leafletFG.addLayer(layer);
			});
		})
		.catch(err => console.warn(err));
	}

	handleLayerClick = ({layer}) => {
		this.setState({isEditEnabled: true, activeLayer: layer.feature, activeLayerFull: layer});
	}

	_onEdited = ({layers}) => {
		layers.eachLayer(layer => {
			const { feature, _latlng } = layer;
			if (feature.id && feature.id !== '')
				this.props.dispatch(updateData(`thing/token/${feature.id}`, {location: [round(_latlng.lng), round(_latlng.lat)]}))
				.then(() => this.getGeoJSON())
				.catch(err => {
					console.warn(err);
					this.getGeoJSON();
				});
		});
	}

	_onCreated = ({layer}) => {
		const geojson = layer.toGeoJSON();
		geojson.properties = {active: false, label: '', description: '', location: [{}]};
		this.setState({activeLayer: geojson, activeLayerFull: layer});
	}

	_onDeleted = ({layers}) => {
		const onConfirm = () => {
			layers.eachLayer(layer => {
				if (!layer.feature || !layer.feature.id) return;
				this.actions.deleteData(`thing/token/${layer.feature.id}`)
					.then(() => this.getGeoJSON());
			});
		}
		this.actions.toggleModal(true,
			<Alert
				onConfirm={onConfirm}
				toggle={() => {
					this.getGeoJSON();
					this.actions.toggleModal();
				}}
				message="do you wish to continue"
				title="drop confirm"
			/>
		);
	}

	render() {
		const { activeLayer } = this.state;

		return (
			<Row className="p-0 m-0">
				<Col className="p-0 m-0">
						<LeafletMap
							style={{width: '100%', height: '70vh'}}
							center={[39.0742, 21.8243]}
							zoom={6}
							onzoomend={this.getGeoJSON}
							onmoveend={this.getGeoJSON}
							ref={this.onMapReady}
						>
							<TileLayer
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
							/>
							{ this.state.leafletLocaleReady &&
								<FeatureGroup ref={this.onFGReady} onClick={this.handleLayerClick}>
									<EditControl
										position="topright"
										draw={{rectangle: false, circle: false, polyline: false, polygon: false, marker: false}}
										onEditStart={() => this.setState({activeLayer: null, activeLayerFull: null})}
										onEdited={this._onEdited}
										onCreated={this._onCreated}
										onDeleteStart={() => this.setState({activeLayer: null, activeLayerFull: null})}
										onDeleted={this._onDeleted}
									/>
								</FeatureGroup>
							}
							{ activeLayer &&
								<Marker position={[activeLayer.geometry.coordinates[1], activeLayer.geometry.coordinates[0]]}/>
							}
						</LeafletMap>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

Things = connect(mapStateToProps)(Things);

export default Things;
